import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as uuid from "uuid";

import { ConfigService } from "../config/config.service";
import { IBackendCallOptions } from "./backend-call-options.interface";

export enum EBackendServiceRequestContentType { 
    ApplicationJson = "application/json",
    ApplicationFormUrlEncoded = "application/x-www-form-urlencoded",
    MultipartFormData = "multipart/form-data",
    TextPlain = "text/plain",
    TextHtml = "text/html"
}

export class NrServerError extends Error {
	constructor(
		public message: string,
		public stacktrace: string,
		public code,
		public data: any
	) {
		super(message);
	}
}

@Injectable({
	providedIn: "root",
})
export class BackendService {
	constructor(private http: HttpClient, private config: ConfigService) {}

	public call(urlPart: string, data: any = null): Observable<any> {
		const uniqueId = uuid.v4();
		const url: string =
			this.config.servicesEndpointRoot + urlPart + "?ts=" + uniqueId;
		data = data || {};
		data.uniqueID = uniqueId;

		return this.http
			.post<any>(url, data, {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				withCredentials: true,
			})
			.pipe(
				catchError((response) => {
					const error = response.error
						? response.error
						: { error: response.statusText };
					throw new NrServerError(
						error.error,
						error.stacktrace,
						error.code,
						error.values
					);
				}),
				map((response) => {
					if (response.result === "OK") {
						return response;
					} else {
						throw new NrServerError(
							response.error,
							response.stacktrace,
							response.code,
							response.values
						);
					}
				})
			);
	}

	public async post(
		urlPart: string,
		data: any = null,
		options: IBackendCallOptions = {}
	): Promise<any> {
		const uniqueId = uuid.v4();
		let url: string =
			(options && options.useAlternate
				? this.config.alternateServicesEndpointRoot
				: this.config.servicesEndpointRoot) +
			urlPart;
            
        url = url.includes("?") ? url + "&ts=" + uniqueId : url + "?ts=" + uniqueId;
        
		data = data || {};
		data.uniqueID = uniqueId;

		try {
			const headers = new HttpHeaders({
                "Content-Type": EBackendServiceRequestContentType.ApplicationJson,
			});
			if (options.hideProgressBar) {
				headers.append("ignoreProgressBar", "");
			}
			const response = await this.http
				.post<any>(url, data, {
					headers,
					withCredentials: true,
				})
				.toPromise();

			if (response.result === "OK") {
				return response;
			} else {
				throw new NrServerError(
					response.error,
					response.stacktrace,
					response.code,
					response.values
				);
			}
		} catch (response) {
			console.error(response);
			const error = response.error
				? response.error
				: { error: response.statusText };
			throw new NrServerError(
				error.error,
				error.stacktrace,
				error.code,
				error.values
			);
		}
	}

    public get(
        url: string,
        contentType: EBackendServiceRequestContentType = EBackendServiceRequestContentType.ApplicationJson
    ): Promise<any> {
        const headers = new HttpHeaders({
            "Content-Type": contentType,
        });
        return this.http
            .get<any>(url, {
                headers: headers
            })
            .toPromise();
    }

	public async postFormData(
		urlPart: string,
		formData: FormData = null,
		options: IBackendCallOptions = {}
	): Promise<any> {
		const uniqueId = uuid.v4();
		const url: string =
			(options && options.useAlternate
				? this.config.alternateServicesEndpointRoot
				: this.config.servicesEndpointRoot) +
			urlPart +
			"?ts=" +
			uniqueId;

		formData.append(`uniqueID`, uniqueId);

		try {
			const headers = new HttpHeaders();

			if (options.hideProgressBar) {
				headers.append("ignoreProgressBar", "");
			}
			const response = await this.http
				.post<any>(url, formData, {
					headers,
					withCredentials: true,
				})
				.toPromise();

			if (response.result === "OK") {
				return response;
			} else {
				throw new NrServerError(
					response.error,
					response.stacktrace,
					response.code,
					response.values
				);
			}
		} catch (response) {
			console.error(response);
			const error = response.error
				? response.error
				: { error: response.statusText };
			throw new NrServerError(
				error.error,
				error.stacktrace,
				error.code,
				error.values
			);
		}
	}
}
