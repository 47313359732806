import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BackendService, BaseDataService, EOperatore, GetPagesRequest, GetPagesResponse, GetWebsitesResponse, IApiRequest, Languages, MenuData, PageLink, PageLinkDetails, UserService, WebsiteDef, WebsiteLanguage } from 'nr-common';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { OperatorLink } from '../shared/models/operatorLink';

type SITE_ACRONIMS = 'IT' | 'FR' | 'DE' | 'NL';

@Injectable()
export class WebsiteService {
	// Flag interni di sincronizzazione
	// public loadingMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public currentWebsite: WebsiteDef;
	public showAdvancedControls$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private websites: WebsiteDef[];
	private _currentOperator: EOperatore = EOperatore.Indefinito;
	private sitePages: GetPagesResponse = null;

	//private _currentOperator$: BehaviorSubject<EOperatore> = new BehaviorSubject<EOperatore>(EOperatore.Indefinito);
	public operatorLinks: OperatorLink[] = [];

	/* 
    public readonly CMS_PAGELINK_ID = {
        home:         { IT: 26, FR: 49, DE: 0, NL: 0 }, // home
        destinations: { IT: 43, FR: 51, DE: 0, NL: 0 }, // destinations
        opendestpage: { IT: 44, FR:  0, DE: 0, NL: 0 }, // destinazioni aperte
        ideas:        { IT: 34, FR:  0, DE: 0, NL: 0 }, // ideas
        offers:       { IT: 35, FR:  0, DE: 0, NL: 0 }, // offers
        contacts:     { IT: 36, FR:  0, DE: 0, NL: 0 }, // contacts
        partners:     { IT: 37, FR:  0, DE: 0, NL: 0 }, // partners
        about:        { IT: 38, FR:  0, DE: 0, NL: 0 }, // about
    }; 
    */
	/* public readonly CMS_PAGELINK_ID = {
        home:           { "Naar": 26, "NaarFrance": 49, "NaarGermany": 0, "NaarBenelux": 0 }, // home
        destinations:   { "Naar": 43, "NaarFrance": 51, "NaarGermany": 0, "NaarBenelux": 0 }, // destinations
        opendestpage:   { "Naar": 44, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // destinazioni aperte
        ideas:          { "Naar": 34, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // ideas
        offers:         { "Naar": 35, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // offers
        contacts:       { "Naar": 36, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // contacts
        partners:       { "Naar": 37, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // partners
        about:          { "Naar": 38, "NaarFrance": 0,  "NaarGermany": 0, "NaarBenelux": 0 }, // about
    }; */

	/* 
    public readonly CMS_FOOTERLINK_ID = {
        IT: 41,
        FR: 50,
        DE: 0,
        NL: 61
    };
    */

	/* public readonly CMS_FOOTERLINK_ID = {
        "Naar": 41,
        "NaarFrance": 50,
        "NaarGermany": 0,
        "NaarBenelux": 61
    }; */

	constructor(
		private user: UserService,
		private backend: BackendService,
		private translate: TranslateService,
		private basedata: BaseDataService
	) {}

	public async init() {
		// Load the websites
		const request: IApiRequest = {};
		const response: GetWebsitesResponse = await this.backend.post('websites/get', request);
		this.websites = response.websites;

		this.setWebsite();

		this.translate.addLangs(['it', 'en', 'fr', 'es', 'de', 'nl']);
		//this.translate.setDefaultLang(this.currentWebsite.defaultLangCode);
		this.translate.use(this.currentWebsite.defaultLangCode);

		const req = new GetPagesRequest({
			lingua: this.basedata.getLingua(),
			operator: this._currentOperator,
		});
		this.sitePages = await this.backend.post('b2c/pages', req);

		this.showAdvancedControls$.next(this.user.can(['intranet']));

		this.setOperatorsAttributes();
	}

	private async setWebsite() {
		// PRODUCTION
		if (typeof environment.website === 'string' && environment.website === 'auto') {
			/* if (this._currentOperator == EOperatore.Indefinito) {
                this._currentOperator = EOperatore.Naar;
            } */
			//let website = this.websites.find((w) => w.operatore == this._currentOperator);

			// Get the website from the URL
			let website = this.websites.find((w) => w.host === window.location.hostname);
			if (!website) {
				website = this.websites[0];
			}
			this.currentWebsite = website;
		} else {
			// DEVELOP: website is fixed in the environment
			this.currentWebsite = this.websites.find((ws) => ws.id == +environment.website);
		}

		this._currentOperator = this.currentWebsite.operatore;
	}

	/* private async setOperator(op: EOperatore, redirect: boolean = false) {
        if (this._currentOperator == op) return;

        this._currentOperator = op;

        // Change website
        this.setWebsite();
        
        // Change language
        const lang = this.currentWebsite.defaultLangCode;
        this.translate.use(lang);

        //await this.user.setLang(lang as Languages);
        //console.log('setOperator', this._currentOperator, this.currentWebsite.id, this.currentWebsite.defaultLangCode, this.translate.currentLang);
        
        if (redirect) {
            let url = '';
            switch (op) {
                case EOperatore.NaarGermany:
                    url = 'home/de';
                    break;
                case EOperatore.NaarBenelux:
                    url = 'home/nl';
                    break;
                case EOperatore.NaarFrance:
                    url = 'home/fr';
                    break;
                default:
                    url = 'home/it';
                    break;
            }
            window.location.href = url;
        }

        // Getting the website structure
        const req = new GetPagesRequest({
            lingua: this.basedata.getLingua(),
            operator: this._currentOperator
        });
        this.sitePages = await this.backend.post('b2c/pages', req);
        this._currentOperator$.next(this._currentOperator);
    } */

	/* public get currentOperator(): Observable<EOperatore> {
        return this._currentOperator$.asObservable();
    } */

	public setLanguage(language: WebsiteLanguage) {
		this.translate.use(language.codice);
	}

	/* public setOperatorByUrl(url: string) {

        // Get the last part of the url
        const lang = url.split('/').pop();

        if (lang != this.translate.currentLang) {
            switch (lang) {
                case 'de':
                    this.setOperator(EOperatore.NaarGermany);
                    break;
                case 'nl':
                    this.setOperator(EOperatore.NaarBenelux);
                    break;
                case 'fr':
                    this.setOperator(EOperatore.NaarFrance);
                    break;
                default:
                    this.setOperator(EOperatore.Naar);
                    break;
            }
        }
    } */

	public formatPageTitle(pageName: string, siteName: string = null): string {
		// console.log("formatPageTitle", `${siteName} - ${pageName}`);
		return `${siteName} - ${pageName}`;
	}

	// Restituisce la chiave per il titolo della pagina in lingua
	public getPageTitleKey(pageName: string): string {
		if (!pageName || pageName === '' || pageName.length === 0) {
			console.warn(`Invalid pageName: "${pageName}".`);
			return '';
		}
		pageName = this.getValidTranslationKey(pageName);
		return `PAGES.${pageName}.TITLE`;
	}

	public getValidTranslationKey(keyName: string): string {
		return keyName.trim().toUpperCase().replace(' ', '-');
	}

	/**
	 * Traduce il nome di pagina delle rotte Angular in id numerico del CMS
	 * @param pageName nome della pagina usata come route di Angular
	 * @returns id numerico della tabella PageLink (per il CMS)
	 */

	public getPageLinkId(pageName: string): number {
		if (this.sitePages == null || this.sitePages.pages == null) return 0;

		const page = this.sitePages.pages.find((p) => p.nome.toLowerCase() === pageName.toLowerCase());
		if (page == null) return 0;
		return page.id;
	}

	/**
	 * Restituisce la lingua usata nella UI
	 * 	la lingua utente "vince" sulla lingua di default del sito
	 * @returns string della lingua in uso
	 */
	/* public getUILanguage(): SITE_ACRONIMS {
        let lingua: string;
        let linguaUser: string;

        lingua = this.currentWebsite.defaultLangCode || 'IT';
        this.user.currentLang$.subscribe((o) => {
            linguaUser = o.toString();
        });

        return lingua.toUpperCase() as SITE_ACRONIMS;
    }
 */
	/**
	 * Restituisce l'ID del footer in funzione della lingua (sito)
	 * @returns id numerico della tabella PageLink afferente al footer (per il CMS)
	 */
	public getFooterLinkId(): number {
		if (this.sitePages == null || this.sitePages.footer == null) return 0;
		return this.sitePages.footer?.id || 0;
	}

	public getInfostrip(): MenuData[] {
		if (this.sitePages == null || this.sitePages.secondMenu == null) return [];

		return this.sitePages.secondMenu?.map((m) => {
			return new MenuData({
				icon: m.faIcon,
				nome: m.nome,
				idLuogo: m.luogoID,
				idPagelink: m.id,
				url: m.url,
			});
		});
	}

	/**
	 * Gestisco la lista degli operatori disponibili (semicablone)
	 * e la traduzione dei loro nomi
	 */
	public setOperatorsAttributes() {
		// const _operatorNames: EOperatore[] = [
		// 	EOperatore.Naar,
		// 	EOperatore.NaarFrance,
		// 	EOperatore.NaarGermany,
		// 	EOperatore.NaarBenelux,
		// ];

		//add operators translate foreach operatorNames in _operatorNames
		// _operatorNames.forEach((operator) => {
		// 	let _name = this.translate.instant(`ENUMS.EOperatore.${operator.toString()}`);
		// 	this.operatorLinks.push(new OperatorLink(EOperatore[operator], _name));
		// });

		// uso basedataservice per ottenere la lista degli operatori
		this.basedata
			.getOperatorsDropdown$()
			.subscribe((operators) => {
				// console.log('operators', operators);
				operators.map((operator) => {
					this.operatorLinks.push(new OperatorLink(operator.value, operator.text));
			});
		});
	}
}
